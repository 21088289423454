import React from 'react'
import Helmet from 'react-helmet'

import Layout from "../../components/layout"
import BgImage from '../../components/bg-image'

const Tiling = () => (
  <Layout>
    <Helmet title="Landscaping and Turfing" />
    <BgImage image="/assets/pool-surrounds.jpg" className="top">
      <h1>Landscaping and Turfing</h1>
    </BgImage>
    <section className="content">
      <p>
        Bring your pool surrounds to life with a design that matches your taste.
        We work with you to make the most of the space you have while adhering
        to structural or regulatory issues to help create the backyard that you
        wont want to leave. Speak to us about how we can help with options such
        as:
      </p>
      <ul>
        <li>Concept Design</li>
        <li>Soft Landscaping</li>
        <li>Turfing</li>
      </ul>
    </section>
  </Layout>
)

export default Tiling
